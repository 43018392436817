import BlackholeTracker from "./blackhole_tracker/client.js";

if (window) {
  window.BlackholeTracker = new BlackholeTracker();
}

(() => {
  //
  // const observeUrlChange = () => {
  //   let oldHref = document.location.href;
  //   const body = document.querySelector("body");
  //   const observer = new MutationObserver(mutations => {
  //     if (oldHref !== document.location.href) {
  //       oldHref = document.location.href;
  //       console.log("Changed");
  //     }
  //   });
  //   observer.observe(body, { childList: true, subtree: true });
  // };
  //
  // window.onload = observeUrlChange;
})();
