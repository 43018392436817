import isBot from "isbot";
import { nanoid } from "nanoid";

import Cookies from "universal-cookie";
const cookies = new Cookies();

const COOKIE_TRACKER_KEY = 't';
const COOKIE_SESSION_KEY = 's';

import TrackerHelper from "./helper";

// Notes
// 1) If we have a tracker key, encrypt it to get a predictable session key
// (DOES NOT WORK BECAUSE AD HAS FIXED TRACKER KEY)
//
// Definition
// 1) Tracker key = Identify source of traffic, i.e. Ad or referral
// 2) Session key = Identify session activity
//
// Strategy
// 1) Tracker key
//    Source: URL, cookie, null => save to cookie => send to server
// 2) Session key
//    Source: Cookie, random => save to cookie => send to server
//

const randomID = () => {
  return nanoid(10);
  return Math.random().toString(36).substr(2, 9);
}

class TrackerClient {

  constructor() {
    this.tracking = true;
    this.initTracker();
    this.initSession();
  }

  shouldTrack() {
    if (!navigator) {
      console.log('[Skip Tracking] !navigator');
      return false;
    }
    if (!URLSearchParams) {
      console.log('[Skip Tracking] !URLSearchParams');
      return false;
    }
    if (isBot(navigator.userAgent)) {
      console.log('[Skip Tracking] bot');
      return false;
    }
    if (!this.tracking) {
      console.log('[Skip Tracking] flag');
      return false;
    }
    console.log('[Tracking]');
    return true;
  }

  initTracker() {
    const getInitialTracker = () => {
      const urlParams = new URLSearchParams(window.location.search);
      // urlParams.get returns null if query param not found
      // cookies.get returns undefined if not found
      let t = urlParams.get(COOKIE_TRACKER_KEY) !== null ? urlParams.get(COOKIE_TRACKER_KEY) : this.getTracker();
      return t;
    }
    let t = getInitialTracker();
    if (typeof t === 'string') {
      if (t.length === 0) { // Reset tracker
        this.resetTracker();
        t = null;
      }
    }
    this.setTracker(t);
  }

  getTracker() {
    return cookies.get(COOKIE_TRACKER_KEY);
  }

  setTracker(t) {
    if (t) {
      cookies.set(COOKIE_TRACKER_KEY, t, { path: '/', expires: new Date(new Date().setFullYear(new Date().getFullYear()+1)) });
    }
  }

  resetTracker() {
    cookies.remove(COOKIE_TRACKER_KEY, { path: '/' });
  }

  initSession() {
    const getInitialSession = () => {
      let s = this.getSession() || randomID();
      return s;
    }
    let s = getInitialSession();
    this.setSession(s);
  }

  getSession() {
    return cookies.get(COOKIE_SESSION_KEY);
  }

  setSession(s) {
    if (s) {
      cookies.set(COOKIE_SESSION_KEY, s, { path: '/', expires: new Date(new Date().setFullYear(new Date().getFullYear()+1)) });
    }
  }

  resetSession() {
    cookies.remove(COOKIE_SESSION_KEY, { path: '/' });
  }

  dispatchEvent(params) {
    navigator.sendBeacon(
      // "/api/v1/log",
      "https://hhmrmrg13l.execute-api.ap-southeast-1.amazonaws.com/prod/jwt/log",
      JSON.stringify(params),
    );
  }

  // Public API
  enableTracking() {
    this.tracking = true;
  }

  disableTracking() {
    this.tracking = false;
  }

  track(c, a, extra) {
    if (!this.shouldTrack()) {
      return;
    }
    let params = {
      [COOKIE_TRACKER_KEY]: this.getTracker(),
      [COOKIE_SESSION_KEY]: this.getSession()
    };
    this.dispatchEvent({
      url: window.location.href,
      ...params,
      c,
      a,
      ...extra,
    });
  }

  convert(c, a, extra) {
    if (!this.shouldTrack()) {
      return;
    }
    this.track(c, a, extra);
    let t = TrackerHelper.updateTracker(this.getTracker(), this.getSession());
    this.setTracker(t);
  }

}

export default TrackerClient;
